<template>
    <!--  -->
    <div class="desk">
        <div class="desk-top">
            <div class="desk-top__left">
                <span class="desk-top__force desk-top__title">Force ID</span>
                <span class="desk-top__id">{{ user.name }}</span>
            </div>
            <div class="desk-top__right">
                <span class="desk-top__partners desk-top__title">Partners</span>
                <span class="desk-top__value"><svg-vue icon="people" />{{ childs.length }}</span>
            </div>
        </div>
        <div class="desk-bottom desk-grid">
            <UiLoader :loading="true" theme="block" v-if="loading" />

            <div class="desk-bottom__inner">
                <div class="desk-bottom-column desk-bottom-column--lg">
                    <div class="desk-grid__item">
                        <div class="desk-grid__avatar">
                            <UiAvatar :user="user" />
                        </div>
                        <div class="desk-grid__id"><span>Force ID</span> {{ user.name }}</div>
                    </div>
                </div>
                <div class="desk-bottom-column desk-bottom-column--md">
                    <div class="desk-grid__item" v-for="child in childs" :key="child.idx">
                        <div class="desk-grid__avatar">
                            <img src="/assets/avatar.jpg" alt="avatar" />
                        </div>
                        <!-- <div class="desk-grid__id"><span>Force ID</span> 1434656</div> -->
                        <div class="desk-grid__id">{{ child.account }}</div>
                    </div>
                </div>
                <!-- <div class="desk-bottom-column desk-bottom-column--sm">
                        <div class="desk-grid__item">
                            <div class="desk-grid__avatar">
                                <img src="/assets/avatar.jpg" alt="avatar" />
                            </div>
                            <div class="desk-grid__id"><span>Force ID</span> 1434656</div>
                        </div>
                        <div class="desk-grid__item">
                            <div class="desk-grid__avatar">
                                <img src="/assets/avatar.jpg" alt="avatar" />
                            </div>
                            <div class="desk-grid__id"><span>Force ID</span> 1434656</div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
    <!--  -->
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"

export default {
    data() {
        return { loading: false, childs: [] }
    },
    props: {
        user: Object,
    },
    async mounted() {
        if (!this.user) return

        this.loading = true

        const [treeErr, treeRes] = await this.requestTree({
            account: this.user.account,
            onNext: (arr) => {
                this.childs = arr
            },
        })

        this.childs = treeRes
        this.loading = false
    },
    methods: {
        ...mapActions("crypto", ["requestTree"]),
    },
}
</script>
