export default {
    mehtods: {
        navigateWithQuery(req) {
            const query = this.$route.query

            console.log(query)
            return { ...req, query }
        }
    }
}
