var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"matrix-card",class:[!_vm.card.active ? 'empty' : 'classic', _vm.buyAvailable && 'buyable', _vm.card.active === 'gap' && 'gap'],attrs:{"to":{ name: 'classiclevel', params: { lvl: _vm.card.lvl + 1 } }}},[_c('div',{staticClass:"matrix-card__inner"},[_c('p',{staticClass:"matrix-card__activation",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleBuyClick.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("matrix.activation")))])]),_c('p',{staticClass:"matrix-card__unavailable"},[_c('span',[_vm._v(_vm._s(_vm.$t("matrix.unavailable")))])]),_c('header',{staticClass:"matrix-card__header"},[_c('span',{staticClass:"matrix-card__lvl"},[_vm._v(_vm._s(_vm.$t("matrix.lvl"))+" "+_vm._s(_vm.card.lvl + 1))]),_c('span',{staticClass:"matrix-card__title"},[_vm._v(_vm._s(_vm.card.type))]),_c('div',{staticClass:"matrix-card__count"},[_c('svg-vue',{attrs:{"icon":"symbol_dai-white"}}),_vm._v(" "+_vm._s(_vm.card.cost)+" ")],1)]),(!_vm.loading && !_vm.error)?_c('div',{staticClass:"matrix-card__tree"},[_c('RenderMiniSvg',{attrs:{"cardType":_vm.card.type,"active":_vm.card.active,"blockchain":_vm.blockchain}})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"matrix-card__tree"},[_c('UiLoader',{attrs:{"loading":true}})],1):_vm._e(),(_vm.card.active)?_c('footer',{staticClass:"matrix-card__footer"},[_c('div',{staticClass:"matrix-card__footer-inner"},[_c('div',{staticClass:"matrix-card__users"},[_c('svg-vue',{attrs:{"icon":"people"}}),_vm._v(" "+_vm._s((_vm.blockchain && _vm.blockchain.totalPartners) || 0)+" ")],1),(_vm.blockchain)?_c('div',{staticClass:"matrix-card__tooltips"},[_c('div',{staticClass:"matrix-card__tooltip",class:[
                            _vm.blockchain.autoRecycle === true && 'matrix-card__tooltip--green',
                            _vm.blockchain.autoRecycle === false && 'matrix-card__tooltip--yellow',
                            _vm.blockchain.autoRecycle === null && 'matrix-card__tooltip--error',
                        ]},[_vm._v(" AR "),_c('span',{staticClass:"matrix-card__tooltiptext"},[(_vm.blockchain.autoRecycle === null)?[_vm._v(" "+_vm._s(_vm.$t("matrix.reActivationError"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("matrix.reActivation"))+": "+_vm._s(_vm.$t(`matrix.${_vm.blockchain.autoRecycle ? "on" : "off"}`))+" ")]],2)]),(_vm.autoUpgradeButtonsAvailable)?_c('div',{staticClass:"matrix-card__tooltip",class:[
                            _vm.blockchain.autoUpgrade === true && 'matrix-card__tooltip--green',
                            _vm.blockchain.autoUpgrade === false && 'matrix-card__tooltip--yellow',
                            _vm.blockchain.autoUpgrade === null && 'matrix-card__tooltip--error',
                        ]},[_vm._v(" AA "),_c('span',{staticClass:"matrix-card__tooltiptext"},[(_vm.blockchain.autoUpgrade === null)?[_vm._v(" "+_vm._s(_vm.$t("matrix.autoUpgradeSecondError"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("matrix.autoUpgrade"))+": "+_vm._s(_vm.$t(`matrix.${_vm.blockchain.autoUpgrade ? "on" : "off"}`))+" ")]],2)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"matrix-card__repeat"},[_c('svg-vue',{attrs:{"icon":"refresh"}}),_vm._v(" "+_vm._s((_vm.blockchain && _vm.blockchain.slot) || 0)+" ")],1)]),_c('div',{staticClass:"matrix-card__percentage"},[_c('span',{staticClass:"matrix-card__percentage-value"},[_vm._v(_vm._s(_vm.progressPercent))]),_c('div',{staticClass:"matrix-card__percentage-line matrix-line"},[_c('div',{staticClass:"matrix-card__percentage-range matrix-line__range",style:({ width: _vm.progressPercent })})])])]):_vm._e(),_c('div',{staticClass:"matrix-card-timer"},[_c('div',{staticClass:"matrix-card-timer__svg"},[_c('svg-vue',{staticClass:"svg-lock",attrs:{"icon":"lock"}}),_c('svg-vue',{staticClass:"svg-unlock",attrs:{"icon":"unlock"}})],1),_c('div',{staticClass:"matrix-card-timer-value"},[_c('span',{staticClass:"matrix-card__hours"},[_vm._v("04")]),_vm._v(" h : "),_c('span',{staticClass:"matrix-card__minutes"},[_vm._v("21")]),_vm._v(" min : "),_c('span',{staticClass:"matrix-card__seconds"},[_vm._v("18")]),_vm._v(" sec ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }