<template>
  <section class="product section-offset">
    <div class="frc-systems-outer">
      <div class="frc-systems" v-if="dataReady">
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Classic</div>
          <div class="system-item__levels system-classic">
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>1</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>2</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76532 48.2347 11.0475 50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.564 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.436 3.20136 18.9525 5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>3</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>4</p>
              <span class="notification">22</span>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>5</p>
              <span class="notification">1</span>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76532 48.2347 11.0475 50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.564 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.436 3.20136 18.9525 5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>6</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>7</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>8</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76532 48.2347 11.0475 50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.564 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.436 3.20136 18.9525 5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="white"
                  fill-opacity="0.2"
                  stroke="black"
                />
              </svg>
              <p>9</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M28 2C32.564 2 37.0475 3.20136 41 5.48334C44.9525 7.76531 48.2347 11.0475 50.5167 15L47.1392 16.95C45.1995 13.5904 42.4096 10.8005 39.05 8.86084C35.6904 6.92116 31.8794 5.9 28 5.9V2Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M50.5167 15C52.7986 18.9525 54 23.436 54 28C54 32.5639 52.7986 37.0475 50.5167 41L47.1392 39.05C49.0788 35.6904 50.1 31.8794 50.1 28C50.1 24.1206 49.0788 20.3096 47.1392 16.95L50.5167 15Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M50.5167 41C48.2347 44.9525 44.9525 48.2347 41 50.5167C37.0475 52.7986 32.564 54 28 54L28 50.1C31.8794 50.1 35.6904 49.0788 39.05 47.1392C42.4096 45.1995 45.1995 42.4096 47.1392 39.05L50.5167 41Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M28 54C23.436 54 18.9525 52.7986 15 50.5167C11.0475 48.2347 7.76532 44.9525 5.48334 41L8.86084 39.05C10.8005 42.4096 13.5904 45.1995 16.95 47.1392C20.3096 49.0788 24.1206 50.1 28 50.1V54Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M5.48334 41C3.20136 37.0475 2 32.564 2 28C2 23.4361 3.20136 18.9525 5.48334 15L8.86084 16.95C6.92116 20.3096 5.9 24.1206 5.9 28C5.9 31.8794 6.92116 35.6904 8.86084 39.05L5.48334 41Z"
                  fill="#FF9501"
                  stroke="black"
                />
                <path
                  d="M5.48334 15C7.76532 11.0475 11.0475 7.76532 15 5.48334C18.9525 3.20136 23.436 2 28 2V5.9C24.1206 5.9 20.3096 6.92116 16.95 8.86084C13.5904 10.8005 10.8005 13.5904 8.86084 16.95L5.48334 15Z"
                  fill="#FF9501"
                  stroke="black"
                />
              </svg>
              <p>10</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
              </svg>
              <p>11</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
              </svg>
              <p>12</p>
            </div>
          </div>
          <div class="system-item__btns">
            <ButtonDark text="Show" classes="frc-systems__button-dark" />
            <p class="tooltip">
              About
              <svg
                style="margin-left: var(--indent-8)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
                />
              </svg>
            </p>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">NFT Royalty</div>
          <div class="system-item__levels system-nft_royalty">
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M54 28C54 42.3594 42.3594 54 28 54C13.6406 54 2 42.3594 2 28C2 13.6406 13.6406 2 28 2C42.3594 2 54 13.6406 54 28ZM5.9 28C5.9 40.2055 15.7945 50.1 28 50.1C40.2055 50.1 50.1 40.2055 50.1 28C50.1 15.7945 40.2055 5.9 28 5.9C15.7945 5.9 5.9 15.7945 5.9 28Z"
                  fill="#FF9501"
                  stroke="black"
                />
              </svg>
              <p>Just</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M54 28C54 42.3594 42.3594 54 28 54C13.6406 54 2 42.3594 2 28C2 13.6406 13.6406 2 28 2C42.3594 2 54 13.6406 54 28ZM5.9 28C5.9 40.2055 15.7945 50.1 28 50.1C40.2055 50.1 50.1 40.2055 50.1 28C50.1 15.7945 40.2055 5.9 28 5.9C15.7945 5.9 5.9 15.7945 5.9 28Z"
                  fill="#FF9501"
                  stroke="black"
                />
              </svg>
              <p>2</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M54 28C54 42.3594 42.3594 54 28 54C13.6406 54 2 42.3594 2 28C2 13.6406 13.6406 2 28 2C42.3594 2 54 13.6406 54 28ZM5.9 28C5.9 40.2055 15.7945 50.1 28 50.1C40.2055 50.1 50.1 40.2055 50.1 28C50.1 15.7945 40.2055 5.9 28 5.9C15.7945 5.9 5.9 15.7945 5.9 28Z"
                  fill="#FF9501"
                  stroke="black"
                />
              </svg>
              <p>3</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>4</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>5</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>6</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <path
                  d="M54 28C54 42.3594 42.3594 54 28 54C13.6406 54 2 42.3594 2 28C2 13.6406 13.6406 2 28 2C42.3594 2 54 13.6406 54 28ZM5.9 28C5.9 40.2055 15.7945 50.1 28 50.1C40.2055 50.1 50.1 40.2055 50.1 28C50.1 15.7945 40.2055 5.9 28 5.9C15.7945 5.9 5.9 15.7945 5.9 28Z"
                  fill="#FF9501"
                  stroke="black"
                />
              </svg>
              <p>7</p>
            </div>
          </div>
          <div class="system-item__btns">
            <router-link :to="{ name: 'nfts' }">
              <ButtonDark text="Show" classes="frc-systems__button-dark" />
            </router-link>
            <p class="tooltip">
              About
              <svg
                style="margin-left: var(--indent-8)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
                />
              </svg>
            </p>
          </div>
        </div>

        <div class="frc-systems__item system-item">
          <div class="system-item__title">United Verse</div>
          <div class="system-item__levels system-united">
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <circle
                  cx="28"
                  cy="29"
                  r="25"
                  stroke="white"
                  stroke-opacity="0.2"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
                <path
                  d="M53 29C53 25.717 52.3534 22.4661 51.097 19.4329C49.8406 16.3998 47.9991 13.6438 45.6777 11.3223C43.3562 9.00087 40.6002 7.15938 37.5671 5.90301C34.5339 4.64664 31.283 4 28 4"
                  stroke="#FF9501"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
              </svg>
              <p>1</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <circle
                  cx="28"
                  cy="29"
                  r="25"
                  stroke="white"
                  stroke-opacity="0.2"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
                <path
                  d="M28 54C31.283 54 34.5339 53.3534 37.5671 52.097C40.6002 50.8406 43.3562 48.9991 45.6777 46.6777C47.9991 44.3562 49.8406 41.6002 51.097 38.5671C52.3534 35.5339 53 32.283 53 29C53 25.717 52.3534 22.4661 51.097 19.4329C49.8406 16.3998 47.9991 13.6438 45.6777 11.3223C43.3562 9.00087 40.6002 7.15938 37.5671 5.90301C34.5339 4.64664 31.283 4 28 4"
                  stroke="#FF9501"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
              </svg>
              <p>2</p>
            </div>
            <div class="system-item__level active">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black" />
                <circle
                  cx="28"
                  cy="29"
                  r="25"
                  stroke="white"
                  stroke-opacity="0.2"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
                <path
                  d="M6.34936 16.5C3.59769 21.266 2.49555 26.8069 3.21388 32.2632C3.93221 37.7194 6.43087 42.7862 10.3223 46.6777C14.2138 50.5691 19.2806 53.0678 24.7368 53.7861C30.1931 54.5045 35.734 53.4023 40.5 50.6506C45.266 47.899 48.9909 43.6515 51.097 38.5671C53.203 33.4826 53.5725 27.8454 52.1481 22.5295C50.7238 17.2137 47.5851 12.5164 43.219 9.16617C38.8529 5.81594 33.5034 4 28 4"
                  stroke="#FF9501"
                  stroke-width="3"
                  stroke-dasharray="1 1"
                />
              </svg>
              <p>3</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>4</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>5</p>
            </div>
            <div></div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>6</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>7</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>8</p>
            </div>
            <div class="system-item__level">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="black"></rect>
              </svg>
              <p>9</p>
            </div>
          </div>
          <div class="system-item__btns">
            <ButtonDark
              text="Coming soon"
              classes="frc-systems__button-dark"
              :disabled="true"
            />
            <p class="tooltip">
              About
              <svg
                style="margin-left: var(--indent-8)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
                />
              </svg>
            </p>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">NFT Classic</div>
          <div class="system-item__levels system-nft_classic"></div>
          <div class="system-item__btns">
            <ButtonDark
              text="Coming soon"
              classes="frc-systems__button-dark"
              :disabled="true"
            />
            <p class="tooltip">
              About
              <svg
                style="margin-left: var(--indent-8)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
                />
              </svg>
            </p>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Metaverse</div>
          <div class="system-item__levels system-metaverse"></div>
          <div class="system-item__btns">
            <ButtonDark
              text="Coming soon"
              classes="frc-systems__button-dark"
              :disabled="true"
            />
            <p class="tooltip">
              About
              <svg
                style="margin-left: var(--indent-8)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white"
                  stroke-opacity="0.3"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="bevel"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>

      <UiLoader v-else theme="block" :loading="true" />
    </div>

    <!-- The content before the redesign is here -->

    <!-- <p class="product__info">{{ $t('realForceInfo.desc') }}</p>

    <div
      class="tabs-offset matrix-grid"
      v-if="activeTab === 'classic' && dataReady"
    >
      <MatrixCard
        v-for="card in classicMatrix"
        :key="card.lvl"
        :card="card"
        :account="account"
      />
    </div>

    <MatrixReferralTree
      v-if="activeTab === 'systems_referral' && dataReady"
      :user="userData"
    />

    <UiLoader v-if="!dataReady" theme="block" :loading="true" /> -->
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import pageReadyByConnect from '@/mixin/pageReadyByConnect'
import MatrixCard from '@/components/Matrix/Card.vue'
import MatrixReferralTree from '@/components/Matrix/ReferralTree.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  data() {
    return {
      id: null,
      account: null,
      userData: null,
    }
  },
  components: {
    MatrixCard,
    MatrixReferralTree,
    ButtonDark,
  },
  mixins: [pageReadyByConnect],
  async mounted() {
    if (this.isConnected) {
      await this.pageReady()
    }
  },
  computed: {
    activeTab() {
      return this.$route.name
    },
    dataReady() {
      return this.account && this.classicMatrix && this.classicMatrix.length
    },
    ...mapState('user', ['classicMatrix']),
    ...mapGetters('user', ['totalActiveLevels']),
    ...mapGetters('crypto', ['getAccount', 'isConnected']),
  },
  methods: {
    navigateTo(name) {
      this.$router.push({ name })
    },
    async pageReady() {
      const [regErr, regParent] = await this.checkRegistration(true)
      if (regErr) {
        this.$swal(this.$t('errors.registrationError'))
        await this.$router.push({ name: 'signup' })
        return
      }

      const accountQuery = this.$route.query.account
      const targetAccount = accountQuery || this.getAccount

      try {
        let userResponse = await this.guessUser(targetAccount)
        if (!userResponse) throw new Error()

        this.id = userResponse.name
        this.account = userResponse.account
        this.userData = userResponse

        this.setClassicMatrix(userResponse.classic)

        // this.$toast.info(userResponse.classic)

        const [err, levels] = await this.getProgramLevels(userResponse.account)

        if (err) {
          this.$toast.error(`Fetch program level from blockchain error: ${err}`)
        }

        this.updateClassicMatrixLevels(levels)
      } catch (err) {
        this.$swal(this.$t('errors.userNotFound'))
        await this.$router.push({ name: 'dashboard' })
      }
    },

    ...mapActions('crypto', ['getProgramLevels', 'checkRegistration']),
    ...mapActions('user', ['guessUser']),
    ...mapMutations('user', ['setClassicMatrix', 'updateClassicMatrixLevels']),
  },
}
</script>

<style lang="scss">
.frc-systems__button-dark {
  background: var(--color-white-10);
  min-width: 174px;
  min-height: 48px;
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  font-weight: var(--font-600);
  color: var(--color-white);
  margin-bottom: var(--indent-14);
  transition: background 500ms;
  &:hover {
    background: var(--color-white-30);
  }
}
</style>
