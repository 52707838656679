<template>
    <router-link
        :to="{ name: 'classiclevel', params: { lvl: card.lvl + 1 } }"
        class="matrix-card"
        :class="[!card.active ? 'empty' : 'classic', buyAvailable && 'buyable', card.active === 'gap' && 'gap']"
    >
        <div class="matrix-card__inner">
            <p class="matrix-card__activation" @click.stop.prevent="handleBuyClick">
                <span>{{ $t("matrix.activation") }}</span>
            </p>
            <p class="matrix-card__unavailable">
                <span>{{ $t("matrix.unavailable") }}</span>
            </p>
            <header class="matrix-card__header">
                <span class="matrix-card__lvl">{{ $t("matrix.lvl") }} {{ card.lvl + 1 }}</span>
                <span class="matrix-card__title">{{ card.type }}</span>
                <div class="matrix-card__count">
                    <svg-vue icon="symbol_dai-white" />
                    {{ card.cost }}
                </div>
            </header>
            <div v-if="!loading && !error" class="matrix-card__tree">
                <RenderMiniSvg :cardType="card.type" :active="card.active" :blockchain="blockchain" />
            </div>
            <div v-if="loading" class="matrix-card__tree">
                <UiLoader :loading="true" />
            </div>
            <!-- <div class="" v-if="error">Ошибка. <button @click.stop.prevent="getStructure">Обовить</button></div> -->
            <footer class="matrix-card__footer" v-if="card.active">
                <div class="matrix-card__footer-inner">
                    <div class="matrix-card__users">
                        <svg-vue icon="people" />
                        {{ (blockchain && blockchain.totalPartners) || 0 }}
                    </div>
                    <div class="matrix-card__tooltips" v-if="blockchain">
                        <div
                            class="matrix-card__tooltip"
                            :class="[
                                blockchain.autoRecycle === true && 'matrix-card__tooltip--green',
                                blockchain.autoRecycle === false && 'matrix-card__tooltip--yellow',
                                blockchain.autoRecycle === null && 'matrix-card__tooltip--error',
                            ]"
                        >
                            AR
                            <span class="matrix-card__tooltiptext">
                                <template v-if="blockchain.autoRecycle === null">
                                    {{ $t("matrix.reActivationError") }}
                                </template>
                                <template v-else>
                                    {{ $t("matrix.reActivation") }}:
                                    {{ $t(`matrix.${blockchain.autoRecycle ? "on" : "off"}`) }}
                                </template>
                            </span>
                        </div>
                        <div
                            class="matrix-card__tooltip"
                            v-if="autoUpgradeButtonsAvailable"
                            :class="[
                                blockchain.autoUpgrade === true && 'matrix-card__tooltip--green',
                                blockchain.autoUpgrade === false && 'matrix-card__tooltip--yellow',
                                blockchain.autoUpgrade === null && 'matrix-card__tooltip--error',
                            ]"
                        >
                            AA
                            <span class="matrix-card__tooltiptext">
                                <template v-if="blockchain.autoUpgrade === null">
                                    {{ $t("matrix.autoUpgradeSecondError") }}
                                </template>
                                <template v-else>
                                    {{ $t("matrix.autoUpgrade") }}:
                                    {{ $t(`matrix.${blockchain.autoUpgrade ? "on" : "off"}`) }}
                                </template>
                            </span>
                        </div>
                    </div>

                    <div class="matrix-card__repeat">
                        <svg-vue icon="refresh" />
                        {{ (blockchain && blockchain.slot) || 0 }}
                    </div>
                </div>

                <div class="matrix-card__percentage">
                    <span class="matrix-card__percentage-value">{{ progressPercent }}</span>
                    <div class="matrix-card__percentage-line matrix-line">
                        <div
                            class="matrix-card__percentage-range matrix-line__range"
                            :style="{ width: progressPercent }"
                        ></div>
                    </div>
                </div>
            </footer>
            <div class="matrix-card-timer">
                <div class="matrix-card-timer__svg">
                    <svg-vue class="svg-lock" icon="lock" />
                    <svg-vue class="svg-unlock" icon="unlock" />
                </div>
                <div class="matrix-card-timer-value">
                    <span class="matrix-card__hours">04</span> h : <span class="matrix-card__minutes">21</span> min :
                    <span class="matrix-card__seconds">18</span> sec
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import RenderMiniSvg from "@/components/Matrix/Render/ClassicMiniSvg.vue"
import { isSameAccount } from "@/helpers/user"
import { Log } from "@/helpers/dev"
import routerNavigation from "@/mixin/routerNavigation"

export default {
    mixins: [routerNavigation],
    data() {
        return { error: false, loading: false, blockchain: null }
    },
    props: {
        card: Object,
        account: String,
    },
    components: { RenderMiniSvg },
    async mounted() {
        // request sub-methods
        if (!this.card.active) return

        await this.getStructure()
    },
    computed: {
        cardActive() {
            // for watcher
            return this.card.active
        },
        progressPercent() {
            if (this.blockchain) {
                const { slot, totalPartners } = this.blockchain
                if (totalPartners) {
                    const totalInSlot = this.card.type === "s6" ? 6 : 3
                    const activeInSlot = Number(slot) !== 0 ? totalPartners - totalInSlot * Number(slot) : totalPartners

                    const calc = Math.round((activeInSlot / totalInSlot) * 100)
                    return `${calc}%`
                }
            }

            return "0%"
        },
        isMyAccount() {
            return isSameAccount(this.getAccount, this.account)
        },
        autoUpgradeButtonsAvailable() {
            if (this.card.lvl === 11) return false
            return this.previousBuyLevel === this.card.lvl
        },
        buyAvailable() {
            if (!this.isMyAccount) return false

            if (this.card.active === "gap") return true
            return this.currentBuyLevel === this.card.lvl
        },
        ...mapGetters("user", ["currentBuyLevel", "previousBuyLevel", "gappedLevel"]),
        ...mapGetters("crypto", ["getAccount", "getMainContract"]),
    },
    methods: {
        async getStructure() {
            this.loading = true
            this.error = false

            const [err, res] = await this.requestStructure({
                account: this.account,
                level: this.card.lvl,
                type: this.card.type,
                countRevenue: false,
                previousActiveSlot: this.card.active === "gap",
            })

            if (err) {
                this.$toast.error(`Fetch structure for level: ${this.card.lvl} from blockchain error: ${err}`)
            }

            if (err) this.error = true

            this.blockchain = res
            Log(`${this.$t("matrix.lvl")} - ${this.card.lvl}`, res)

            this.loading = false
        },
        handleBuyClick() {
            if (this.gappedLevel !== null && this.gappedLevel < this.card.lvl) {
                this.$swal(this.$t("matrix.activateGap", { lvl: this.card.lvl + 1 }))
                return
            }
            this.setActivationModal({ visible: true, type: this.card.type, level: this.card.lvl, cost: this.card.cost })
        },
        ...mapActions("crypto", ["requestStructure"]),
        ...mapMutations("ui", ["setActivationModal"]),
    },
    watch: {
        cardActive(newVal, oldVal) {
            if (newVal !== false && !oldVal) {
                this.getStructure()
            }
        },
    },
}
</script>

<style lang="scss">
.matrix-card__buy {
    pointer-events: all;
}
</style>
